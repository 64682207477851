.banner__container {
      display: flex;
      margin-bottom: 50px;
      margin-left: 50px;
      margin-right: 50px;
      padding-left: 30px;;
      padding-right: 30px;;
}

.about__content {
      margin: 50px 0;
      padding: 0 50px;
}



.banner__image img {
      width: 100%;
}

.banner__detail {
      padding: 0 5%;
      width: 50%;
}

.banner__detail p {
      font-size: 2.5rem;
}


.about__container {
      padding: 10px 0px;
      margin-bottom: 50px;
}

.about__item {
      display: flex;
      position: relative;
      width: 80%;
}

.about__title {
      font-size: 34px;
    padding-bottom: 10px;
}

.about__detail ul li {
      list-style: disc;
}

.bottom__padding {
      --e-transform-translateX: -400px;
}

.guiding__padding {
      --e-transform-translateX: -330px;
}

.sustainable__padding {
      --e-transform-translateX: -230px;
}


.padding__left {
      padding-left: 20px;
}

.about__summary {
      /* padding-left: 30px; */
      /* margin-bottom: 20px; */
      padding-bottom: 20px;
      /* border-bottom: 1px solid #18181833; */
      font-size: 28px;
}

.about__detail {
      font-size: 18px;
      /* margin-left: 50px; */
      text-align: justify;
      /* border-left: 1px solid #18181833; */
}

.about__detail  {
        /* padding-left: 30px; */
        padding-right: 30px;
  }

  @media (max-width: 1280px) {
      .about__content .banner__container {
            display: grid;
            padding-top: 0 !important;
      }

      .about__item {
            width: 100%;   
            margin-top: 20px;   
      }

      .banner__image {
            width: 100%;
            height: auto;
            background-repeat: round;
            height: 320px;
      }

      .position1 {
            order: 1;
      }

      .position2 {
            order: 2;
      }
  }