@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.prime-title {
      /* margin-top: 120px; */
      font-family: 'Montserrat', sans-serif;
      font-size: 40px;
      color: #666;
}

@media screen and (max-width: 512px) {
      .prime-title {
            font-size: 24px;
      }
}

.prime-loader {
      width: 100%;
      margin: 0 auto;
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
}

.ball {
      position: absolute;
      border-radius: 100%;
      opacity: 0.7;
      z-index: 9999999999;
      overflow: hidden;
}