.breadcrumb__container {
      padding: 10px 50px 0;
}

.catalogue__body {
      display: grid;
      grid-template-columns: repeat(3,minmax(0,1fr));
      gap: 20px;
      margin-bottom: 50px;
      padding: 0 50px;
}

@media screen and (max-width: 1200px) {
      .catalogue__body {
            grid-template-columns: repeat(2,minmax(0, 1fr));
      }
}


@media screen and (max-width: 640px) {
      .catalogue__body {
            grid-template-columns: repeat(1,minmax(0,1fr));
            padding: 0;
      }
}

.catalogue__item {
      border: 1px solid #f2f2f2;
}

.catalogue__item-image {
      border-bottom: 1px solid #f2f2f2    ;
}

.catalogue__item-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
}

.catalogue__item-image a {
      display: block;
      height: 322px;
} 

.catalogue__item-title {
      font-size: 18px;
}

.catalogue__item-title a {
      color: #d71a21;
}

.catalogue__item-detail {
      /* display: flex; */
      /* justify-content: space-between; */
      padding: 20px 15px;
      background-color: #d9d9d9;
}

.catalogue__item-icon {
      height: 20px;
      /* width: 20px; */
      font-size: 20px;
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 5px 0;
      justify-content: space-between;
}

.catalogue__item-icon a {
      color: #68798b;
      font-weight: 400;
      font-size: 16px;
}

.catalogue__item-share {
      display: flex;
      gap: 10px;
      align-items: center;
}

.catalogue__item-share button {
      color: black;
      font-size: 18px;
      line-height: 0;
      background-color: transparent;
      border: 0;
}