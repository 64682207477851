.category__container .nav-link.active,
.mobile__filter .nav-link.active {
      background-color: #666 !important;
      color: white;
}

.category__container .nav-link.active > .filter__item .filter__icon svg {
      fill: white !important;
}


.category__container .category__product {
      width: calc(100% - 380px);
}

.category__container {
      display: flex;
      margin-bottom: 50px;
}     

.category__container .category__content {
      padding: 20px 50px;
}

.category__container .category__body .category__body-title {
      font-size: 22px;
      margin-top: 10px;
      font-weight: 600;
}



.category__container .category__product-similar a {
      border: 1px solid #666;
      padding: 2.5px 5px;
      display: inline-block;
      font-size: 14px;
      border-radius: 5px;
}


.category__container .category__product-buy {
      background-color: white;
      border: 1px solid #333;
}

.category__container .category__product-button a {
      text-align: center;
      padding: 5px;
      border-top: 1px solid #f2f2f2;
      background-color: #d71a21;
      color: white;
      width: 100%;
      display: inline-block;
}

.nav-link:focus-visible {
      outline: 0;
      box-shadow: none;
  }
  .nav-link:focus{
      color: #333;
  }