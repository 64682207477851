.blog__title {
      color: #666;
}

.blog__header {
      margin-bottom: 30px;
}

.blog__summary {
      font-size: 20px;
}

.blog__container {
      margin: 50px 0;
      padding: 0 50px;
}

.blog__detail small {
      color: #a7a5a5;
}

.blog__item {
      border: 1px solid #f2f2f2;
      background-color: #fff;
}

.blog__image a {
      display: inline-block;
      width: 100%;
}

.blog__item a {
      color: #666;
}

.blog__image img {
      height: 168px;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px;
      object-fit: contain;
}

.blog__detail {
      padding: 10px 20px;
      min-height: 120px;
}

.blog__detail p {
      margin: 0;
}

.blog__widget {
      background-color: #dfdddd;
      padding: 50px 20px;
}

.blog__widget-title {
      font-size: 20px;
      font-weight: 600;
}

.blog__widget-header {
      margin-bottom: 20px;
}