.product__image-slider {
      position: sticky;
      top: 40px;
}

.slider__thumb {
      border: 1px solid #FFF;
      background-color: white;
}

.slider__thumb img {
      height: 80px;
      width: 100%;
      padding: 10px;
}

.product__image {
      height: 350px;
}

.product__image img {
      height: 350px;
      object-fit: contain;
}

.swiper-thumbs {
      margin-top: 10px;
}

.product__image-slider .swiper-button-disabled {
      display: none;
}

.mySwiper2 {
      border: 1px solid #FFF;
      padding: 10px;
      background-color: white;
}

.product__header .product__title {
      font-weight: 600;
      font-size: 24px;
      color: #000;
      padding-bottom: 10px;
      padding-top: 10px;
      border-bottom: 1px solid #FFF;
}

.product__header .product__amount {
      display: flex;
      padding: 15px 0;
      font-size: 18px;
      color: #666;
      align-items: center;
}

.product__header .product__amount .product__price span {
      color: #333;
      padding-left: 20px;
}

.product__select select {
      background-color: #9b9898;
      color: white;
      padding-left: 10px;
      margin-left: 10px;
}

.product__form {
      background-color: #FFF;
      padding: 30px;
}

.product__body .form-group {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-bottom: 20px;
}

.product__body .form-group span {
      color: #666;
}

.product__body .form-group label {
      margin: 0;
}

.product__body .form-group .form-control {
      height: 40px;
      width: 200px;
      border-radius: 0;
}

.product__form-button {
      display: flex;
}

.product__form-cart {
      color: #FFF;
      padding: 5px 20px;
      border: 2px solid green;
      background-color: green;
      border-radius: 10px;
      margin-right: 20px;
}

.product__form-buy {
      color: #FFF;
      padding: 5px 20px;
      border: 2px solid green;
      background-color: green;
      border-radius: 10px;
}

.product__detail {
      background-color: white;
}

.product__addition {
      margin: 50px 0;
}

.product__addition-title {
      font-size: 24px;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-bottom: 0;
      padding-left: 20px;
}

.product__addition-description {
      display: grid;
      grid-template-columns: repeat(2,minmax(0,1fr));
      gap: 20px;
      padding: 20px;
}

.product__description-detail {
      padding: 20px;
}

.product__description-detail ul {
      padding-left: 0;
}

.product__addition-item {
      display: flex;
      border: 1px solid #f2f2f2;
      padding: 10px 20px;
      border-radius: 5px;
      color: #666;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      background-color: #f2f2f2;
}

.product__addition-image {
      padding-right: 30px;
}

.product__container {
      margin: 50px 0;
      padding: 0 50px;
}

.product__description-title {
      padding: 20px 0;
      font-size: 20px;
      color: #666;
      border-bottom: 2px solid #f2f2f2;
      margin-bottom: 30px;
}

.product__description-detail {
      color: #666;
}

.product__description-detail ul li {
      display: flex;
      padding-bottom: 10px;
}

.product__description-detail ul li div {
      width: 200px;
}

  #header a {
      display: block;
      /* padding: 15px 25px; */
  }

  .mobile__filter {
      display: none;
  }

  @media screen and (max-width: 1200px) {
      .filter__container {
            display: none;
      }

      .category__container .category__product {
            width: 100%;
      }

      .mobile__filter {
            display: block;
            padding: 20px 50px;
      }

      .offcanvas-body {
            padding: 0;
      }
  }

  .mobile__filter-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      padding: 20px;
  }


  .mobile__filter-button {
      display: flex;
      justify-content: end;
      /* align-items: center; */
      gap: 10px;
      cursor: pointer;
  }

  .mobile__filter-icon {
      height: 24px;
  }
  