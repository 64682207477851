.career__container {
      margin: 50px 0;
    padding: 0 50px;
}

.career__btn {
      border: 1px solid #333;
      padding: 8px 20px;
      width: 100%;
      background-color: transparent;
}

.career__btn:hover {
      background-color: #333;
      color: white;
}

.faq__ask-body {
      background-color: white;
}