#preloader {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99999999999999999999;
      overflow: hidden;
      background-image: url('./images/image2.jpg');
      display: flex;
      justify-content: center;
      align-items: center;
}

#preloader {
      background-color: #fff;
      background-position: 50%;
      z-index: 99999999999999999999999999;
}
@media screen and (max-width: 767px) {
      #preloader img {
            width: 150px;
            height: auto !important;
      }
}
.banner-slider {
      border-radius: 20px;
}

.banner-slider .swiper-button-prev,
.banner-slider .swiper-button-next {
      background-color: white !important;
      border-radius: 50%;
      padding: 20px 22px;
      opacity: 0;
      transition: all 0.3s ease;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      transition: background-color 0.3s ease;
}

.banner-slider .swiper-button-next:after, .banner-slider .swiper-button-prev:after {
      font-size: 24px;
      color: #D71920;
}

.banner-slider .swiper-button-prev:hover,
.banner-slider .swiper-button-next:hover {
      background-color: #231F20 !important;
}

.banner-slider .swiper-button-prev:hover > .swiper-button-prev:after {
      color: white !important;
}

.banner-slider:hover>.swiper-button-next,
.banner-slider:hover>.swiper-button-prev {
    opacity: 1;
}

a {
      color: #7c7c7c;
}

.trending-slider {
      padding: 20px !important;
      margin-right: 50px !important;
}

@media screen and (max-width: 964px) {
      .trending-slider {
            padding-left: 0 !important;
            margin-right: 0 !important;
      }
}

.form-control {
      border-radius: 0 !important;
}

.form-control:focus {
      box-shadow: none !important;
}

.sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 99;
      -webkit-animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1)
            both;
      -moz-animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
      animation: stickySlideDown 1s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@keyframes stickySlideDown {
      0% {
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
      }

      100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
      }
}

.load-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      z-index: 10;
      pointer-events: none;
}

.load-screen {
      position: relative;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #19bc8b;
      width: 0%;
      height: 100%;
}

.load-screen1 {
      position: relative;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #e4cb58;
      width: 100%;
      height: 0%;
}

.smooth-scroll-wrapper {
      position: fixed;
      z-index: 2;
      top: 0;
      left: 0;
      overflow: hidden;
}

.container {
      max-width: 1536px;
}

@media (min-width: 1536px) {
      .container-lg {
            max-width: 1536px;
      }
}

.faq__ask-title {
      color: white;
}

.faq__ask {
      position: -webkit-sticky;
      position: sticky;
      top: 50px;
}

.faq__ask-header {
      background-color: #d71a21;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 12px 20px;
}

.faq__ask-body {
      border: 1px solid #f2f2f2;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 20px;
}
@media (max-width: 576px) {
      .container,
      .container-fluid,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl,
      .container-xxl {
            --bs-gutter-x: 2.5rem !important;
            --bs-gutter-y: 0;
      }
}

.pagination > li > a {
      background: #fafafa;
      color: #666;
}

.pagination.pagination-flat > li > a {
      border-radius: 0 !important;
}

.pagination {
      border-radius: 4px;
      display: inline-block;
      margin: 20px 0;
      padding-left: 0;
}

.pagination > li {
      display: inline;
}

.pagination > li > a,
.pagination > li > span {
      background-color: #fff;
      border: 1px solid #ddd;
      color: #D71A21;
      float: left;
      line-height: 1.42857143;
      margin-left: -1px;
      padding: 6px 12px;
      position: relative;
      text-decoration: none;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      margin-left: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
      background-color: #eee;
      border-color: #ddd;
      color: #D71A21;
      z-index: 2;
      box-shadow: none;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
      background-color: #D71A21;
      border-color: #D71A21;
      color: #fff;
      cursor: default;
      z-index: 3;
      box-shadow: none;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
      background-color: #fff;
      border-color: #ddd;
      color: #777;
      cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
      font-size: 18px;
      line-height: 1.3333333;
      padding: 10px 16px;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
      font-size: 12px;
      line-height: 1.5;
      padding: 5px 10px;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
}

.pager {
      list-style: none;
      margin: 20px 0;
      padding-left: 0;
      text-align: center;
}

.pager li {
      display: inline;
}

.pager li > a,
.pager li > span {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
      display: inline-block;
      padding: 5px 14px;
}

.pager li > a:focus,
.pager li > a:hover {
      background-color: #eee;
      text-decoration: none;
}

.pager .next > a,
.pager .next > span {
      float: right;
}

.pager .previous > a,
.pager .previous > span {
      float: left;
}

.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
      background-color: #fff;
      color: #777;
      cursor: not-allowed;
}

/* Remove Search Filter */
.mm-navbars--top {
      display: none;
}

