.form-select:focus {
      box-shadow: none;
}

.dealer__contact-icon {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 5px;
      padding-left: 10px;
}
.dealer__location {
      cursor: pointer;
}
.dealer__contact-icon i {
      line-height: 1;
}

.dealer__contact-icon div {
      padding-left: 20px;
}

.dealer__contact-item {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #f5f5f5;
      /* height: 240px; */
      border-radius: 10px;
}
.dealer__contact-item a {
      color: #212529;
}
.dealer__contact-item:hover {
      border: 1px solid #d71a21;
      transition: 0.3s ease-in-out;
}

.dealer__contact-title {
      font-size: 18px;
      margin-bottom: 5px;
}
.modal-backdrop {
      --bs-backdrop-zindex: 1050;
      --bs-backdrop-bg: rgba(0, 0, 0, 1);
      --bs-backdrop-opacity: 0.1;
      position: fixed;
      top: 0;
      left: 0;
      z-index: var(--bs-backdrop-zindex);
      width: 100vw;
      height: 100vh;
      background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.show {
      opacity: var(--bs-backdrop-opacity);
}
